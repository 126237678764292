import _throttle from 'lodash.throttle';
import { MutableRefObject, useSyncExternalStore } from 'react';

const FIXED_BOTTOM = 30;
const MODIFY_FIXED_BOUNDARY = 380;

type ShouldWrapperBeFixedPropsType = {
  elementNode: HTMLElement;
  wrapperNode: HTMLElement;
  fixedBottom: number | undefined;
};

/**
 * Функция, определяющая видимость обертки в зависимости от положения.
 * @param props - пропсы
 * @param props.elementNode - узел элемента;
 * @param props.wrapperNode - узел обертки;
 * @param props.fixedBottom - величина до фиксируемого низа.
 */
const shouldWrapperBeFixed = ({
  elementNode,
  wrapperNode,
  fixedBottom = FIXED_BOTTOM,
}: ShouldWrapperBeFixedPropsType) => {
  const elementTopPosition =
    elementNode.getBoundingClientRect().top + MODIFY_FIXED_BOUNDARY;
  const isElementTopAboveViewport = elementTopPosition < 0;

  const blockButtomPosition =
    wrapperNode.getBoundingClientRect().bottom + fixedBottom;
  const isBlockBelowViewport = blockButtomPosition > window.innerHeight;

  return isElementTopAboveViewport && isBlockBelowViewport;
};

type UseFloatingWrapperType = (props: {
  elementRef: MutableRefObject<HTMLElement | null>;
  wrapperRef: MutableRefObject<HTMLElement | null>;
  shouldFloat: boolean;
  fixedBottom?: number;
}) => boolean;

/**
 * Хук, навешивает событие на скролл, возвращает true, если обертка должна плавать.
 * @param props - пропсы
 * @param props.elementRef - ссылка на элемент;
 * @param props.wrapperRef - ссылка на обертку;
 * @param props.shouldFloat - флаг плавающих при скролле кнопок;
 * @param props.fixedBottom - величина до фиксируемого низа.
 */
export const useFloatingWrapper: UseFloatingWrapperType = ({
  elementRef,
  wrapperRef,
  shouldFloat,
  fixedBottom,
}) => {
  const subscribe = (callback: () => void) => {
    const throttledChangeCssPosition = _throttle(callback, 30);

    window.addEventListener('scroll', throttledChangeCssPosition);

    return () => {
      window.removeEventListener('scroll', throttledChangeCssPosition);
    };
  };

  const toggleFixed = () => {
    if (!elementRef.current || !wrapperRef.current || !shouldFloat) {
      return false;
    }

    return shouldWrapperBeFixed({
      elementNode: elementRef.current as HTMLElement,
      wrapperNode: wrapperRef.current as HTMLElement,
      fixedBottom,
    });
  };

  return useSyncExternalStore(subscribe, toggleFixed, () => false);
};

import { AUTOTAG_TYPE } from './autotag/typings';
import { PAGE_TYPE } from './router/typings';
import { FORMAT_TOPIC_ALIASES, TOPIC_ALIAS_STARLIFE } from './topic/constants';

export const ROOT_ID_NAME = 'app';

export const EDITOR_HEADER = 'О проекте';

export const BRANDING_CLASS_NAME = 'commercial-branding';

export const DEFAULT_IMAGE = 'https://news.rambler.ru/special/no_img.jpg';

/**
 * Функция-заглушка
 */
export const defaultCallback = () => {};

/**
 * Функция получения флага, что страница из списка списочных страниц.
 * @param pageName - имя страницы.
 */
export const getIsListPage = (pageName: PAGE_TYPE) =>
  [
    PAGE_TYPE.autotag,
    PAGE_TYPE.tag,
    PAGE_TYPE.clusterItems,
    PAGE_TYPE.date,
    PAGE_TYPE.latest,
    PAGE_TYPE.theme,
  ].includes(pageName);

/**
 * Функция получения флага страницы персоны.
 * @param pageName - имя страницы;
 * @param autotagType - тип автотега.
 */
export const getIsPersonPage = (
  pageName: PAGE_TYPE,
  autotagType: AUTOTAG_TYPE | null,
) => getIsListPage(pageName) && autotagType === AUTOTAG_TYPE.person;

/**
 * Функция получения флага, что страница редактора или эксперта.
 * @param pageName - имя страницы.
 */
export const getIsEditorOrExpertPage = (pageName: PAGE_TYPE) =>
  [PAGE_TYPE.editor, PAGE_TYPE.expert].includes(pageName);

/**
 * Функция получения флага, что это страница путеводителя.
 * @param pageName - название страницы
 */
export const checkIsGuidePage = (pageName: PAGE_TYPE) =>
  pageName === PAGE_TYPE.guide;

/**
 * Функция получения флага форматного топика.
 * @param topicAlias - алиас топика.
 */
export const getIsFormatTopic = (topicAlias: TopicType['alias']) =>
  FORMAT_TOPIC_ALIASES.includes(topicAlias);

/**
 * Функция получения флага топика Шоу-бизнес.
 * @param topicAlias - алиас топика.
 */
export const getIsStarlifeTopic = (
  topicAlias: TopicType['alias'] | undefined,
) => TOPIC_ALIAS_STARLIFE === topicAlias;

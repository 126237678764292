import cn from 'classnames';
import React, { useRef } from 'react';

import { ScrollButton } from 'common/components/ScrollButton';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useFloatingWrapper } from 'desktop/hooks/useFloatingWrapper';

import s from './styles.module.css';

type ScrollbackDeskPropsType = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  shouldFloat?: boolean;
};

/**
 * Кнопка возрата в начало страницы.
 * @param props - пропсы
 * @param props.elementRef - ссылка на элемент;
 * @param props.shouldFloat - флаг плавающих при скролле кнопок.
 */
const ScrollbackDeskComponent = function ScrollbackDesk({
  elementRef,
  shouldFloat = true,
}: ScrollbackDeskPropsType) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isFixed = useFloatingWrapper({
    elementRef,
    wrapperRef,
    shouldFloat,
    fixedBottom: 0,
  });

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={cn(s.scrollback, { [s.scrollback_fixed]: isFixed })}>
        <ScrollButton
          elementRef={elementRef}
          top100Value="bottom_buttons::button_up"
          styles={s}
        />
      </div>
    </div>
  );
};

export const ScrollbackDesk = withErrorBoundary(ScrollbackDeskComponent);
